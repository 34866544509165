import axios from 'axios';
import { Stock } from './model';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/search/stocks`;

export const fetchStocks = async (params = {}): Promise<Stock[]> => {
  const response = await axios.post(`${API_URL}`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    }
  );
  return response.data.stocks;
};
