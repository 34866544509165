import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSelectionStocks } from '../../../features/select/slice';
import { CheckOutlined } from '@ant-design/icons';

const SelectButton: React.FC = () => {
    const selectedStocks = useSelector(getSelectionStocks);
    const navigate = useNavigate()

    const select = () => {
        navigate("/stocks/selected")
    };

    return (
        <Button
            onClick={select}
            type="primary"
            shape="round"
            icon={<CheckOutlined style={{ fontSize: '16px' }} />}
            disabled={selectedStocks.length === 0}
        >
            선택
        </Button>
    );
};

export default SelectButton;