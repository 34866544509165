import React from 'react';
import { koreanCurrency, yearMonthFormatter } from '../../utils';
import { MoneyInMonth } from '../../features/select/model';
import { Table, TableProps } from 'antd';
import { useSelector } from 'react-redux';
import { getSimulation } from '../../features/select/slice';

interface DataType {
    yearAndMonth: string;
    dividend: number;
    asset: number;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: '일자',
        dataIndex: 'yearAndMonth',
        key: 'yearAndMonth',
        render: (text) => <p>{yearMonthFormatter(text)}</p>,
    },
    {
        title: '배당금',
        dataIndex: 'dividend',
        key: 'dividend',
        render: (value) => <p>{koreanCurrency(value)}원</p>
    },
    {
        title: '자산',
        dataIndex: 'asset',
        key: 'asset',
        render: (value) => <p>{koreanCurrency(value)}원</p>
    }
]

const SimulationTable: React.FC = () => {
    const simulation = useSelector(getSimulation);

    const data = (): MoneyInMonth[] => {
        if (simulation === undefined) return [];

        const { dividendInMonths, assetInMonths } = simulation;
        const assetMap = new Map(assetInMonths.map(item => [item.yearAndMonth, item.asset]));

        const data = dividendInMonths.map(dividendItem => {
            const asset = assetMap.get(dividendItem.yearAndMonth) || 0;
            return {
                yearAndMonth: dividendItem.yearAndMonth,
                dividend: dividendItem.dividend,
                asset: asset
            };
        });

        return data
    }

    if (simulation === undefined) return null;
    return (
        <Table<DataType>
            sticky={true}
            dataSource={data()}
            columns={columns}
            pagination={false}
            bordered={true}
            style={{ width: '80%' }}
        />
    );
};

export default SimulationTable;
