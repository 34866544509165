import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { getExchange, getFilterItems } from '../../../features/filter/slice';
import { fetchStocksAsync, getStocksStatus } from '../../../features/stocks/slice';
import { NetworkStatus } from '../../../types/NetworkStatus';
import { SearchOutlined } from '@ant-design/icons';

const SearchButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const status = useSelector(getStocksStatus);
    const exchange = useSelector(getExchange);
    const filters = useSelector(getFilterItems);

    const search = () => {
        const validRangeFilters = filters.filter(item => item.range !== undefined);
        const validStringFilters = filters.filter(item => item.string !== undefined);

        const categoryParam = { 'category': exchange.key };
        const rangeParams = validRangeFilters.reduce((object, item) => (
            { ...object, [item.key]: item.range }), categoryParam
        )
        const params = validStringFilters.reduce((object, item) => (
            { ...object, [item.key]: item.string }), rangeParams
        )

        dispatch(fetchStocksAsync(params));
    };

    return (
        <Button
            onClick={search}
            type="primary"
            shape="round"
            loading={status === NetworkStatus.Loading}
            icon={<SearchOutlined style={{ fontSize: '16px' }} />}
        >
            검색하기
        </Button>
    );
};

export default SearchButton;