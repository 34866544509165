export interface Stock {
  name: string;
  ticker: string;
  price: number;
  marketCap?: number;
  per?: number;
  pbr?: number;
  eps?: number;
  bps?: number;
  opm?: number;
  opmRate?: number;
  revenue?: number;
  netProfitRate?: number;
  deptRatio?: number;
  quickRatio?: number;
  priceChange?: number;
  oneDayChange?: number;
  lastDividendRate?: number;
  dividendChange?: number;
}

interface StringDictionary {
  [key: string]: string;
}

export const StockHeaderMap: StringDictionary = {
  name: "이름",
  ticker: "종목코드",
  price: "전일종가",
  marketCap: "시가총액",
  per: "PER",
  pbr: "PBR",
  eps: "EPS",
  bps: "BPS",
  opm: "영업이익",
  opmRate: "영업이익률",
  revenue: "매출",
  netProfitRate: "순이익률",
  deptRatio: "부채비율",
  quickRatio: "당좌비율",
  priceChange: "수익률",
  oneDayChange: "전일비",
  lastDividendRate: "최근 1년 배당률",
  dividendChange: "5년 평균 배당성장률",
  dividendGrowthSince: "배당 연속 성장기간"
}

export interface Head {
  key: string;
  label: string;
  numeric: boolean;
}