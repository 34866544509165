import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { NetworkStatus } from '../../../types/NetworkStatus';
import { fetchSimulationAsync, getSimulationParam, getSimulationRatio, getSimulationStatus } from '../../../features/select/slice';
import { SimulationRequest } from '../../../features/select/model';

const SimulateButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const param = useSelector(getSimulationParam);
    const ratios = useSelector(getSimulationRatio);
    const status = useSelector(getSimulationStatus);

    const handleSimulate = () => {
        const tickerAndRatios = ratios.map(e => ({ ticker: e.ticker, ratio: e.ratio }))
        const request: SimulationRequest = {
            tickerAndRatios,
            period: param.year,
            monthlyAddMoney: param.moneyPerMonth,
            initialMoney: param.initialMoney,
            dividendTaxIncluded: true,
        }
        dispatch(fetchSimulationAsync(request))
    }

    return (
        <>
            <Button
                onClick={handleSimulate}
                type="primary"
                shape="round"
                loading={status === NetworkStatus.Loading}
            >
                시뮬레이션
            </Button>
        </>
    );
};

export default SimulateButton;