import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchStocks } from './api';
import { Stock } from './model';
import { RootState } from '../../store/store';
import { NetworkStatus } from '../../types/NetworkStatus';

interface StocksState {
  stocks: Stock[];
  stock: Stock | null;
  status: NetworkStatus;
  error: string | null;
}

const initialState: StocksState = {
  stocks: [],
  stock: null,
  status: NetworkStatus.Idle,
  error: null,
};

export const fetchStocksAsync = createAsyncThunk('stocks/fetchStocks', async (params: object) => {
  return await fetchStocks(params);
});

const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStocksAsync.pending, (state) => {
        state.status = NetworkStatus.Loading;
      })
      .addCase(fetchStocksAsync.fulfilled, (state, action: PayloadAction<Stock[]>) => {
        state.status = NetworkStatus.Succeeded;
        state.stocks = action.payload;
      })
      .addCase(fetchStocksAsync.rejected, (state, action) => {
        state.status = NetworkStatus.Failed;
        state.error = action.error.message || 'Failed to fetch stocks';
      })
  },
});

export const selectAllStocks = (state: RootState) => state.stocks.stocks;
export const selectStock = (state: RootState) => state.stocks.stock;
export const getStocksStatus = (state: RootState) => state.stocks.status;
export const getStocksError = (state: RootState) => state.stocks.error;

export default stocksSlice.reducer;
