import React from 'react';
import { Area, Bar, ComposedChart, Legend, XAxis, YAxis, Tooltip } from 'recharts';
import { koreanCurrency, shortKoreanCurrency, yearMonthFormatter } from '../../utils';
import { MoneyInMonth } from '../../features/select/model';
import { Flex } from 'antd';
import { useSelector } from 'react-redux';
import { getSimulation } from '../../features/select/slice';
import { blue, green } from '@ant-design/colors';

const SimulationChart: React.FC = () => {
    const simulation = useSelector(getSimulation);

    const data = (): MoneyInMonth[] => {
        if (simulation === undefined) return [];

        const { dividendInMonths, assetInMonths } = simulation;
        const assetMap = new Map(assetInMonths.map(item => [item.yearAndMonth, item.asset]));

        const data = dividendInMonths.map(dividendItem => {
            const asset = assetMap.get(dividendItem.yearAndMonth) || 0;
            return {
                yearAndMonth: dividendItem.yearAndMonth,
                dividend: dividendItem.dividend,
                asset: asset
            };
        });

        return data
    }

    if (simulation === undefined) return null;
    return (
        <Flex>
            <ComposedChart
            width={1000}
            height={300}
            data={data()}
            style={{ width: '100%' }}
        >
            <XAxis dataKey="yearAndMonth" name="Height" tickFormatter={(value, index) => `${yearMonthFormatter(value)}`} />
            <YAxis yAxisId="left" orientation="left" stroke={blue.primary} tickLine={false} tickFormatter={(value, index) => `${shortKoreanCurrency(value)}`} />
            <YAxis yAxisId="right" orientation="right" stroke={green.primary} tickLine={false} tickFormatter={(value, index) => `${shortKoreanCurrency(value)}`} />
            <Tooltip formatter={(value, name, props) => [props.value ? koreanCurrency(props.value as number) : 0, props.name === 'asset' ? '자산' : '배당금']} />
            <Legend formatter={(value, entry, index) => (value === 'asset' ? '자산' : '배당금')} />
            <Area yAxisId="left" dataKey="asset" fill={blue.primary} stroke={blue.primary} />
            <Bar yAxisId="right" dataKey="dividend" fill={green.primary} />
        </ComposedChart>
        </Flex>
    );
};

export default SimulationChart;
