import React, { useEffect, useState } from 'react';
import { Range, filterValueTypes, FilterOption, rangeToLabel } from '../../../features/filter/model';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, InputNumber, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterItems, updateFilterRangeItem, updateFilterStringItem } from '../../../features/filter/slice';
import { AppDispatch } from '../../../store/store';
import { koreanCurrency } from '../../../utils';

interface FilterDrawerContentProps {
    selectedOption: FilterOption | undefined;
}

const FilterDrawerContent: React.FC<FilterDrawerContentProps> = ({ selectedOption }) => {
    const dispatch = useDispatch<AppDispatch>();
    const filterItems = useSelector(getFilterItems);

    const [selectedRange, setSelectedRange] = useState<Range | undefined>(undefined);
    const [selectedPeriod, setSelectedPeriod] = useState<number | undefined>(undefined);
    const [selectedString, setSelectedString] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (selectedOption !== undefined) {
            const range = filterItems.find((item) => (
                item.key === selectedOption!.key
            ))?.range
            if (range !== undefined) {
                setSelectedRange(range)
                setSelectedPeriod(range.periodInMonth)
            } else {
                setSelectedRange(undefined)
                setSelectedPeriod(undefined)
            }

            const string = filterItems.find((item) => (
                item.key === selectedOption!.key
            ))?.string
            if (string !== undefined) {
                setSelectedString(string)
            } else {
                setSelectedString(undefined)
            }
        } else {
            setSelectedRange(undefined)
            setSelectedPeriod(undefined)
        }
    }, [selectedOption, filterItems])

    const onChangeRange = (range?: Range) => {
        setSelectedRange(range)
        onChange(range, selectedPeriod, undefined)
    };

    const onChangePeriod = (period?: number) => {
        setSelectedPeriod(period)
        onChange(selectedRange, period, undefined)
    };

    const onChangeString = (string?: string) => {
        setSelectedString(string)
        onChange(undefined, undefined, string)
    };

    const onChange = (range?: Range, period?: number, string?: string) => {
        if (selectedOption === undefined) return;

        if (range !== undefined) {
            if (period !== undefined) {
                const targetRange: Range = { min: range.min, max: range.max, periodInMonth: period }
    
                dispatch(updateFilterRangeItem({ name: selectedOption.key, range: targetRange }))
            } else {
                const targetRange: Range = { min: range.min, max: range.max }
    
                dispatch(updateFilterRangeItem({ name: selectedOption.key, range: targetRange }))
            }
            return
        } else if (string !== undefined) {
            dispatch(updateFilterStringItem({ name: selectedOption.key, string: string }))
        }
    }

    return (<Content style={{ padding: '24px', minHeight: 280 }}>
        {selectedOption ? (
            <div>
                <h2>{selectedOption.label}</h2>
                <p>{selectedOption.description}</p>

                <Space direction='vertical'>
                    {selectedOption.type === filterValueTypes.range && (
                        <>
                            {selectedOption.ranges!
                                .map((range, index) => {
                                    const isSelectedRange = selectedRange?.min === range.min && selectedRange?.max === range.max

                                    return <Button
                                        type={isSelectedRange ? "primary" : "default"}
                                        key={index}
                                        onClick={() => onChangeRange(range)}
                                    >
                                        {rangeToLabel(range, selectedOption.unit)}
                                    </Button>
                                })}

                            <Space>
                                <InputNumber
                                    value={selectedRange?.min}
                                    max={selectedRange?.max}
                                    formatter={value => koreanCurrency(value)}
                                    onChange={(value) => onChangeRange({ min: value ?? undefined, max: selectedRange?.max })}
                                />
                                <div>~</div>
                                <InputNumber
                                    value={selectedRange?.max}
                                    min={selectedRange?.min}
                                    formatter={value => koreanCurrency(value)}
                                    onChange={(value) => onChangeRange({ min: selectedRange?.min ?? 0, max: value ?? undefined })}
                                />
                            </Space>

                            <Col style={{ height: '32px' }} />

                            {selectedOption.periodTypes !== undefined && (
                                <Space>
                                    {selectedOption.periodTypes.map((period, index) => {
                                        const isSelected = selectedPeriod === period

                                        return <Button
                                            type={isSelected ? "primary" : "default"}
                                            key={10 + index}
                                            onClick={() => onChangePeriod(period)}
                                        >
                                            {period} 개월
                                        </Button>
                                    })}

                                    <InputNumber
                                        min={1}
                                        precision={0}
                                        max={60}
                                        suffix="개월"
                                        onChange={(value) => onChangePeriod(value ?? undefined)}
                                    />
                                </Space>
                            )}
                        </>
                    )}

                    {selectedOption.type === filterValueTypes.string && (
                        <Space>
                            <InputNumber
                                value={+(selectedString ?? 0) > 0 ? +(selectedString ?? 0) : null}
                                min={2019}
                                precision={0}
                                max={2024}
                                suffix="년"
                                onChange={(value) => onChangeString(value?.toString() ?? undefined)}
                            />
                        </Space>
                    )}
                </Space>
            </div>
        ) : (
            <h2>필터를 선택하세요.</h2>
        )}
    </Content>
    )
};

export default FilterDrawerContent;