import { Flex } from 'antd'
import WeightSegmented from "../components/simulation/buttons/WeightSegmented";
import SimulationStockList from "../components/simulation/SimulationStockList";
import SimulationChart from "../components/simulation/SimulationChart";
import InputsSection from '../components/simulation/sections/InputsSection';
import SimulationTable from '../components/simulation/ SimulationTable';

const StockSimulationPage: React.FC = () => {
    return (
        <Flex
            vertical
            gap={'middle'}
            justify={"space-between"}
            align={'center'}
            style={{ padding: '16px' }}
        >
            <WeightSegmented />
            <SimulationStockList />
            <InputsSection />
            <SimulationChart />
            <SimulationTable />
        </Flex>
    )
}

export default StockSimulationPage