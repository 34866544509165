import React from 'react';
import { Flex } from 'antd';
import FilterHeader from '../components/filter/FilterHeader';
import FilterStockList from '../components/filter/FilterStockList';
import SelectSection from '../components/filter/sections/SelectSection';

const FilterPage: React.FC = () => {
    return (
        <Flex
            gap="middle"
            vertical
            style={{ padding: '16px' }}
        >
            <FilterHeader />
            <FilterStockList />
            <SelectSection />
        </Flex>
    );
};

export default FilterPage;
