import axios from "axios";
import {SimulationRequest, SimulationResponse} from "./model";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/simulation/future`;

export const fetchSimulation = async (params: SimulationRequest): Promise<SimulationResponse> => {
    const response = await axios.post(API_URL,
        params,
        {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        })
    console.log(response.data)
    return response.data;
};