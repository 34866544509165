export interface Weight {
    key: string;
    name: string;
}

export const enum WeightKey {
    MarketCap = 'marketcap',
    Equal = 'equal',
  }

export const weights: Weight[] = [
    { key: WeightKey.MarketCap, name: "시총분배" },
    { key: WeightKey.Equal, name: "균등분배" },
]

export const enum SimulationParamKey {
    InitialMoney = 'initialMoney',
    MoneyPerMonth = 'moneyPerMonth',
    Year = 'year',
    DividendGrowthRate = 'dividendGrowthRate',
    DividendRate = 'dividendRate'
}

export interface SimulationParam {
    initialMoney: number
    moneyPerMonth: number
    year: number
    dividendGrowthRate: number
    dividendRate: number
}

export const initialParam: SimulationParam = {
    initialMoney: 0,
    moneyPerMonth: 0,
    year: 1,
    dividendGrowthRate: 0,
    dividendRate: 0
}

export interface SimulationRequest {
    tickerAndRatios: TickerAndRatio[]
    period: number,
    monthlyAddMoney: number,
    initialMoney: number,
    dividendTaxIncluded: boolean
}

export interface TickerAndRatio {
    ticker: string,
    ratio: number,
}

export interface SimulationResponse {
    originalMoney: number
    assetInEnd: number
    dividendInMonths: DividendInMonth[]
    assetInMonths: AssetInMonth[]
}

export interface AssetInMonth {
    asset: number
    yearAndMonth: string
}

export interface DividendInMonth {
    dividend: number
    yearAndMonth: string
}

export interface MoneyInMonth {
    yearAndMonth: string;
    dividend: number;
    asset: number;
}