import { Divider, Flex, Typography } from "antd";
import { Col } from 'antd';
import ExchangeSegmented from "../buttons/ExchangeSegmented";

const { Text } = Typography;

const ExchangeSection: React.FC = () => {
    return (
        <Flex align={'middle'}>
            <Col 
                flex="80px"
                style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text strong>거래소</Text>
            </Col>
            <Col
                style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Divider type="vertical" />
            </Col>
            <Col
                style={{
                    maxWidth: '100vw',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
            >
                <ExchangeSegmented />
            </Col>
        </Flex>
    );
};

export default ExchangeSection;