import React from 'react';
import FilterButton from './FilterButton';
import { useSelector } from 'react-redux';
import { getFilterItems } from '../../../features/filter/slice';
import { stockFilterOptions } from '../../../features/filter/model';

const FilterButtons: React.FC = () => {
    const items = useSelector(getFilterItems);

    return (
        <div>
            {
                items
                    .filter(item => item.range !== undefined || item.string !== undefined)
                    .map(item => stockFilterOptions.find(option => option.key === item.key)!)
                    .map((option) => (
                        <div key={option.key} style={{ display: 'inline-block', marginRight: '8px' }}>
                            <FilterButton option={option} />
                        </div>
                    ))
            }
        </div>
    )
};

export default FilterButtons;