import React from 'react';
import { Flex } from 'antd';
import ExchangeSection from './sections/ExchangeSection';
import FilterSection from './sections/FilterSection';
import SearchSection from './sections/SearchSection';

const FilterHeader: React.FC = () => {
    return (
        <Flex
            gap="middle"
            vertical
        >
            <ExchangeSection />
            <FilterSection />
            <SearchSection />
        </Flex>
    );
};

export default FilterHeader;
