import { Stock, StockHeaderMap } from "../features/stocks/model";

export const round = (value: number, unit: number = 2) => Math.round(value * 10 ** unit) / (10 ** unit)

export const currency = (value: number | undefined) => {
    if (value === undefined) return value;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const one_만 = 10_000;                   // 1만 (ten thousand)
const one_억 = 100_000_000;              // 1억 (hundred million)
const one_조 = 1_000_000_000_000;        // 1조 (trillion)
const one_경 = 10_000_000_000_000_000;   // 1경 (quadrillion)

export const koreanCurrency = (value?: number): string => {
    console.log(value);
    if (value === undefined) return '0';
    const isMinus = value < 0;
    let absValue = Math.abs(value);
    console.log(absValue);
    let result = '';

    const 경 = Math.floor(absValue / one_경);
    if (경 > 0) {
        result += `${경}경 `;
        absValue %= one_경;
    }

    const 조 = Math.floor(absValue / one_조);
    if (조 > 0) {
        result += `${조}조 `;
        absValue %= one_조;
    }

    const 억 = Math.floor(absValue / one_억);
    if (억 > 0) {
        result += `${억}억 `;
        absValue %= one_억;
    }

    const 만 = Math.floor(absValue / one_만);
    if (만 > 0) {
        result += `${만}만 `;
        absValue %= one_만;
    }

    if (absValue > 0) {
        result += `${absValue}`;
    }

    if (result.length === 0) return '0';

    if (isMinus) {
        result = '-' + result;
    }

    return result.trim();
};

export const shortKoreanCurrency = (value?: number): string => {
    if (value === undefined) return '';

    const 경 = Math.floor(value / one_경);
    if (경 > 0) {
        return `${value / one_경} 경`;
    }

    const 조 = Math.floor(value / one_조);
    if (조 > 0) {
        return `${value / one_조} 조`;
    }

    const 억 = Math.floor(value / one_억);
    if (억 > 0) {
        return `${value / one_억} 억`;
    }

    const 만 = Math.floor(value / one_만);
    if (만 > 0) {
        return `${value / one_만} 만 `;
    }

    return `${value}`;
};

export const yearMonthFormatter = (value?: string): string => {
    if (value === undefined) return '';
    const [year, month] = value.split("/");
    if (!year || !month || year.length !== 4 || month.length !== 2) {
        return '';
    }

    return `${year}년 ${month}월`;
}

export const stockTableHeaderFormatter = (key: string) => {
    if (key === 'marketCap' || key === 'price' || key === 'priceChange' || key === 'oneDayChange') return (v: number) => `${koreanCurrency(v)}원`;
    else if (key.includes('Rate') || key.includes('Ratio')) return (v: number) => `${round(v * 100)}%`
    else if (key === 'ticker') return undefined;
    else return (v: number) => `${currency(v)}`;
}

export const generateColumnsByDatasource = (datasource: Stock[]) => {
    if (datasource.length === 0) return [];
    const sample = datasource[0];

    return Object.keys(sample)
        .filter((key) => StockHeaderMap[key] !== undefined)
        .filter((key) => datasource.some(row => row[key as keyof Stock] !== undefined))
        .filter((key) => datasource.some(row => row[key as keyof Stock] !== null))
        .map((key) => ({
            key: key as keyof Stock,
            dataIndex: key as keyof Stock,
            title: StockHeaderMap[key],
            width: key === 'marketCap' ? '180px' : '100px',
            render: stockTableHeaderFormatter(key),
            sorter: (a: Stock, b: Stock) => a[key as keyof Stock]! > b[key as keyof Stock]! ? 1 : -1,
            fixed: key === 'name' ? 'left' : undefined,
        }));
}