import React from 'react';
import { Segmented } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { weights } from '../../../features/select/model';
import { getWeight, selectWeight } from '../../../features/select/slice';

const WeightSegmented: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const weight = useSelector(getWeight);
    
    const onChange = (weightName: string) => {
        const exchange = weights.find(weight => weight.name === weightName)!
        dispatch(selectWeight(exchange));
    }

    return (
        <Segmented
            size="large"
            defaultValue={weight.name}
            options={weights.map(weight => weight.name)}
            onChange={onChange}
        />
    );
};

export default WeightSegmented;