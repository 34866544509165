import React from 'react';
import { Segmented } from 'antd';
import { exchanges } from '../../../features/filter/model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { getExchange, resetFilters, selectExchange } from '../../../features/filter/slice';

const ExchangeSegmented: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const exchange = useSelector(getExchange);
    
    const onChange = (exchangeName: string) => {
        const exchange = exchanges.find(exchange => exchange.name === exchangeName)!
        dispatch(selectExchange(exchange))
        dispatch(resetFilters())
    }

    return (
        <Segmented
            size="large"
            defaultValue={exchange.name}
            options={exchanges.map(exchange => exchange.name)}
            onChange={onChange}
        />
    );
};

export default ExchangeSegmented;