import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Exchange, exchanges, Range, StockFilterItem, stockFilterItems } from './model';
import { RootState } from '../../store/store';

interface StockFilterState {
    exchange: Exchange;
    items: StockFilterItem[];
}

const initialState: StockFilterState = {
    exchange: exchanges[0],
    items: stockFilterItems
};

const stockFilterSlice = createSlice({
    name: 'stockFilter',
    initialState,
    reducers: {
        selectExchange: (state, action: PayloadAction<Exchange>) => {
            state.exchange = action.payload
        },
        updateFilterRangeItem: (state, action: PayloadAction<{name: string, range: Range}>) => {
            state.items = state.items.map(item => 
                item.key === action.payload.name 
                ? { ...item, range: action.payload.range }
                : item
            )
        },
        updateFilterStringItem: (state, action: PayloadAction<{name: string, string: string}>) => {
            state.items = state.items.map(item => 
                item.key === action.payload.name 
                ? { ...item, string: action.payload.string }
                : item
            )
        },
        resetFilters: (state) => {
            state.items = stockFilterItems
        },
        removeFilter: (state, action: PayloadAction<{key: string}>) => {
            state.items = state.items.map(item =>
                item.key === action.payload.key
                ? { ...item, range: undefined, string: undefined}
                : item
            )
        },
    },
});

export const getExchange = (state: RootState) => state.stockFilter.exchange;
export const getFilterItems = (state: RootState) => state.stockFilter.items;

export const { selectExchange, updateFilterRangeItem, updateFilterStringItem, resetFilters, removeFilter } = stockFilterSlice.actions;
export default stockFilterSlice.reducer;