import { Col, Divider, Flex, Typography } from "antd";
import FilterButtons from "../buttons/FilterButtons";
import { useState } from "react";
import FilterDrawer from "../drawer/FilterDrawer";
import FilterAddButton from "../buttons/FilterAddButton";

const { Text } = Typography;

const FilterSection: React.FC = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <Flex align={'middle'}>
            <Col
                flex="80px"
                style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text strong>필터</Text>
            </Col>
            <Col
                style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Divider type="vertical" />
            </Col>
            <Col>
                <FilterAddButton onClick={showDrawer} />
                <FilterDrawer open={open} close={closeDrawer} />
            </Col>
            <Col flex="16px" />
            <Col
                style={{
                    maxWidth: '100vw',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
            >
                <FilterButtons />
            </Col>
        </Flex>
    );
};

export default FilterSection;