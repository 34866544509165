import { Flex, Space } from "antd";
import SimulationParamInput from "../input/SimulationParamInput";
import { SimulationParamKey } from "../../../features/select/model";
import SimulateButton from "../buttons/SimulateButton";
import { useSelector } from "react-redux";
import { getSimulationParam } from "../../../features/select/slice";
import { currency, round } from "../../../utils";

const InputsSection: React.FC = () => {
    const param = useSelector(getSimulationParam);

    return (
        <Flex justify={"space-between"} style={{ marginBottom: '20px' }}>
            <Space size={"large"} align={"end"}>
                <SimulationParamInput title="초기 금액" step={10000} paramKey={SimulationParamKey.InitialMoney} value={param.initialMoney} formatter={(value) => `${currency(value)}원`} />
                <SimulationParamInput title="월 적립 금액" step={10000} paramKey={SimulationParamKey.MoneyPerMonth} value={param.moneyPerMonth} formatter={(value) => `${currency(value)}원`} />
                <SimulationParamInput title="투자 기간" step={1} paramKey={SimulationParamKey.Year} value={param.year} formatter={(value) => `${value}년`} />
                <SimulationParamInput title="평균 배당 성장률" inputMode="decimal" disabled={true} paramKey={SimulationParamKey.DividendGrowthRate} value={param.dividendGrowthRate} formatter={(value) => `${round((value ?? 0) as number * 100)}%`} />
                <SimulationParamInput title="평균 배당률" inputMode="decimal" disabled={true} paramKey={SimulationParamKey.DividendRate} value={param.dividendRate} formatter={(value) => `${round((value ?? 0) as number * 100)}%`} />
                <SimulateButton />
            </Space>
        </Flex>
    );
};

export default InputsSection;