import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Stock } from '../stocks/model';
import { fetchSimulation } from "./api";
import { initialParam, SimulationParam, SimulationRequest, SimulationResponse, Weight, weights } from "./model";
import { NetworkStatus } from '../../types/NetworkStatus';

interface StockSelectState {
    weight: Weight;
    param: SimulationParam;
    ratios: any[];
    stocks: Stock[];
    simulation?: SimulationResponse;
    status: NetworkStatus;
}

const initialState: StockSelectState = {
    weight: weights[0],
    param: initialParam,
    ratios: [],
    stocks: [],
    status: NetworkStatus.Idle
};

const stockSelectionSlice = createSlice({
    name: 'stockSelection',
    initialState,
    reducers: {
        selectWeight: (state, action: PayloadAction<Weight>) => {
            state.weight = action.payload
        },
        select: (state, action: PayloadAction<Stock[]>) => {
            state.stocks = action.payload
        },
        setSimulationParam: (state, action: PayloadAction<SimulationParam>) => {
            state.param = action.payload
        },
        setSimulationRatio: (state, action: PayloadAction<any[]>) => {
            state.ratios = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSimulationAsync.pending, (state) => {
                state.status = NetworkStatus.Loading;
            })
            .addCase(fetchSimulationAsync.fulfilled, (state, action: PayloadAction<SimulationResponse>) => {
                state.status = NetworkStatus.Succeeded;
                state.simulation = action.payload
            })
            .addCase(fetchSimulationAsync.rejected, (state, action) => {
                state.status = NetworkStatus.Failed;
            })
    },
});

export const getWeight = (state: RootState) => state.stockSelection.weight;
export const getSelectionStocks = (state: RootState) => state.stockSelection.stocks;
export const getSimulationParam = (state: RootState) => state.stockSelection.param;
export const getSimulationRatio = (state: RootState) => state.stockSelection.ratios;
export const getSimulation = (state: RootState) => state.stockSelection.simulation;
export const getSimulationStatus = (state: RootState) => state.stocks.status;

export const { selectWeight, select, setSimulationParam, setSimulationRatio } = stockSelectionSlice.actions;
export default stockSelectionSlice.reducer;

export const fetchSimulationAsync = createAsyncThunk('stockSelection/fetchSimulation', async (params: object) => {
    return await fetchSimulation(params as SimulationRequest);
});
