import React from 'react';
import { Button } from 'antd';

interface FilterButtonProps {
    onClick: () => void;
}

const FilterAddButton: React.FC<FilterButtonProps> = ({ onClick }) => {
    return (<Button
        onClick={onClick}
        type="primary"
        shape="round"
    >
        {"필터 추가"}
    </Button >)
};

export default FilterAddButton;