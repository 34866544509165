import React from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { resetFilters } from '../../../features/filter/slice';
import { ReloadOutlined } from '@ant-design/icons';

const ResetButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    
    const reset = () => {
        dispatch(resetFilters())
    }

    return (
        <>
            <Button
                onClick={reset}
                shape="round"
                icon={<ReloadOutlined style={{ fontSize: '16px' }} />}
                >
                초기화
            </Button>
        </>
    );
};

export default ResetButton;