import { configureStore } from '@reduxjs/toolkit';
import stocksReducer from '../features/stocks/slice';
import stockFilterReducer from '../features/filter/slice';
import stockSelectionReducer from '../features/select/slice'

export const store = configureStore({
  reducer: {
    stocks: stocksReducer,
    stockFilter: stockFilterReducer,
    stockSelection: stockSelectionReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
