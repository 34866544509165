import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import SelectButton from "../buttons/SelectButton";
import { getStocksStatus } from "../../../features/stocks/slice";
import { NetworkStatus } from "../../../types/NetworkStatus";

const SelectSection: React.FC = () => {
    const status = useSelector(getStocksStatus);

    let content;
    if (status === NetworkStatus.Succeeded) {
        content = <>
            <Row align={'middle'} justify="start">
                <Col flex="8px" />
                <Col>
                    <SelectButton />
                </Col>
            </Row>
        </>;
    } else {
        content = <div />;
    }

    return (
        <section >
            {content}
        </section>
    );
};

export default SelectSection;