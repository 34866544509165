import React from 'react';
import { InputNumber, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { getSimulationParam, setSimulationParam } from '../../../features/select/slice';
import { SimulationParamKey } from '../../../features/select/model';

interface SimulationParamInputProps {
    title: string;
    step?: number;
    inputMode?: "numeric" | "decimal";
    disabled?: boolean;
    paramKey: SimulationParamKey;
    value: number;
    formatter: ((value?: number) => string);
}

const SimulationParamInput: React.FC<SimulationParamInputProps> = ({ title, step, inputMode, disabled, paramKey, value, formatter }) => {
    const dispatch = useDispatch<AppDispatch>();
    
    const param = useSelector(getSimulationParam);

    const onChange = (value: number | null) => {
        if (value === null) return;
        const paramValue = value ? value - value % 1 : value
        dispatch(setSimulationParam({...param, [paramKey] : paramValue}))
    }

    return (
        <div>
        <Typography.Title level={5}>{title}</Typography.Title>
        <InputNumber
            key={paramKey}
            style={{ width: '80%' }}
            step={step}
            size={"large"}
            inputMode={inputMode ? inputMode : "numeric"}
            disabled={disabled ? disabled : false}
            formatter={(value) => formatter(value)}
            value={value}
            onChange={(value) => onChange(value)}
            min={0}
        />
    </div>
    );
};

export default SimulationParamInput;