import { Col, Row } from "antd";
import SearchButton from "../buttons/SearchButton";
import ResetButton from "../buttons/ResetButton";

const SearchSection: React.FC = () => {
    return (
        <Row align={'middle'} justify="start">
            <Col flex="8px" />
            <Col>
                <SearchButton />
            </Col>
            <Col flex="8px" />
            <Col>
                <ResetButton />
            </Col>
        </Row>
    );
};

export default SearchSection;