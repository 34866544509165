import React from 'react';
import { Button, Divider } from 'antd';
import { FilterOption, rangeToLabel } from '../../../features/filter/model';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterItems, removeFilter } from '../../../features/filter/slice';
import { AppDispatch } from '../../../store/store';
import { CloseOutlined } from '@ant-design/icons';

interface FilterButtonProps {
    option: FilterOption;
}

const FilterButton: React.FC<FilterButtonProps> = ({ option }) => {
    const dispatch = useDispatch<AppDispatch>();

    const currentItem = useSelector(getFilterItems).find((item) => (item.key === option.key))!;

    const remove = () => {
        dispatch(removeFilter({ key: currentItem.key }))
    }

    return (<Button
        key={option.key}
        shape="round"
        icon={<CloseOutlined style={{ fontSize: '10px' }} />}
        iconPosition={'end'}
        style={{
            paddingLeft: '16px',
            paddingRight: '16px',
        }}
        onMouseEnter={(e) => {
            e.currentTarget.style.color = '#ff4d4f';
            e.currentTarget.style.borderColor = '#ff4d4f';
        }}
        onMouseLeave={(e) => {
            e.currentTarget.style.color = '#000000';
            e.currentTarget.style.borderColor = '#d9d9d9';
        }}
        onClick={remove}
    >
        {currentItem.range ? (
            <div>
                {option.label}
                <Divider type="vertical" />
                {rangeToLabel(currentItem.range, option.unit)}
            </div>
        ) : null }

        {currentItem.string ? (
            <div>
                {option.label}
                <Divider type="vertical" />
                {`${currentItem.string}${option.unit}`}
            </div>
        ) : null }
    </Button >)
};

export default FilterButton;